import addClass from './addClass';
import removeClass from './removeClass';

/**
 * Toggles a classe on a DOM element
 *
 * @param {Element} element Element for that the class should get toggled
 * @param {string} className Classname that should get toggled
 * @param {boolean} state Optional. A Boolean (not just truthy/falsy) value to determine whether the class should be added or removed.
 * @returns {void}
 */
export default (element, className, state) => {
  const shouldRemoveClass = typeof state === 'boolean' ? !state : element.classList.contains(className);

  if (shouldRemoveClass) {
    removeClass(element, className);
  } else {
    addClass(element, className);
  }
};
