/**
 * Gets the next element by selector
 * @param {Object} el Element the sibling is searched for
 * @param {Object} selectorString Selector the sibling should match to
 * @returns {Object} Next element matchig the selector
 */
export default (el, selectorString) => {
  const nextElementCandidate = el.nextElementSibling;
  let nextElement;

  if (nextElementCandidate && nextElementCandidate.matches(selectorString)) {
    nextElement = nextElementCandidate;
  }

  return nextElement;
};
