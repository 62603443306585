const responsiveVideos = () => {
  const videos = document.getElementsByTagName('video');

  [...videos].forEach((video) => {
    const sources  = video.getElementsByTagName('source');
    let matchFound, srcChange = false;

    [...sources].forEach((source)=> {
      const media = source.getAttribute('media');

      if(!matchFound && (window.matchMedia(media).matches || !media)) {
        matchFound = true;
        if(!source.hasAttribute('src')) {
          srcChange = true;
          source.setAttribute('src', source.dataset.src);
        }
      } else {
        source.removeAttribute('src');
      }
    });

    if (srcChange) {
      video.load();
      video.play();
    }
  });
};

export default responsiveVideos;
