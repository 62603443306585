import { tns } from 'tiny-slider';

export default async function init() {
  const imageSlider = document.querySelectorAll('.slider:not([data-initialized])');

  if (imageSlider.length) {
    imageSlider.forEach(element => {
      const slideShow = element;
      slideShow.dataset.initialized = true;

      const slider = tns({
        container: slideShow.querySelector('.slider__slides'),
        autoWidth: true,
        nav: false,
        center: true,
        autoplay: false,
        prevButton: slideShow.querySelector('.slider__prev'),
        nextButton: slideShow.querySelector('.slider__next'),
        loop: false,
        edgePadding: 10,
        rewind: true,
        mouseDrag: true,
        gutter: 10,
        responsive: {
          1280: {
            gutter: 50
          },
          1024: {
            gutter: 30
          },
          768: {
            items: 20
          }
        }
      });

      slider.events.on('indexChanged', (info, eventName) => {
        const prevCaption = slideShow.querySelector('.slider__caption[aria-visible=true]');
        prevCaption.setAttribute('aria-visible', 'false');
        const nextCaption = slideShow.querySelector(`.slider__caption[data-slide="${info.index + 1}"]`);
        nextCaption.setAttribute('aria-visible', 'true');
      });
    });
  }
}
