import { LitElement, html, css } from 'lit';

class ModalTrigger extends LitElement {
  static get properties() {
    return {
      modalId: {
        type: String
      }
    };
  }

  static get styles() {
    return [
      css`
        button {
          width: 100%;
          padding: 0;
          cursor: pointer;
          background: none;
          appearance: none;
          border: none;
          outline: none;
        }

        button[disabled] {
          pointer-events: none;
          cursor: auto;
        }

        :host-context([data-whatinput='keyboard']) button:focus {
          outline: 2px dashed currentColor;
          outline-offset: 2px;
          z-index: 1;
        }
      `
    ];
  }

  firstUpdated() {
    window.addEventListener(`modal-close-${this.modalId}`, () => this.handleClose());
  }

  handleOpen() {
    const ev = new CustomEvent(`modal-open-${this.modalId}`, { bubbles: true });
    this.dispatchEvent(ev);
  }

  handleClose() {
    this.shadowRoot.querySelector('button').focus();
  }

  render() {
    return html`
      <button @click="${this.handleOpen}">
        <slot></slot>
      </button>
    `;
  }
}

customElements.define('kpn-modal-trigger', ModalTrigger);

export default ModalTrigger;
