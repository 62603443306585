export default async function init() {
  // const twitter = document.querySelector('kpn-twitter-embed');
  // if (twitter) {
  //   await import(/* webpackChunkName: "twitter-embed" */ './twitter');
  // }
  // const facebook = document.querySelector('kpn-facebook-embed');
  // if (facebook) {
  //   await import(/* webpackChunkName: "facebook-embed" */ './facebook');
  // }
  // const soundcloud = document.querySelector('kpn-soundcloud-embed');
  // if (soundcloud) {
  //   await import(/* webpackChunkName: "soundcloud-embed" */ './soundcloud');
  // }
}
