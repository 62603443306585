import getNextSibling from '../../../javascripts/dom/getNextSibling';

const callbackFnc = async (event) => {
  const elements = document.querySelectorAll('kpn-maps');

  const consent = event.detail || window.CookieFirst.consent;
  if (elements.length > 0 && consent.functional) {
    elements.forEach((element) => {
      const blocker = getNextSibling(element, '.cookiefirst-disabled-resource');
      if (blocker) {
        blocker.remove();
      }
    });

    await import(/* webpackChunkName: "maps" */ './maps');
  }
};

export default async function init() {
  window.addEventListener('cf_consent', callbackFnc);
  window.addEventListener('cf_init', callbackFnc);
}
