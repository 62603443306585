import '../stylesheets/main.scss';

import htmx from 'htmx.org';
import throttle from 'lodash/throttle';

// Use what-input to track input method
import 'what-input';

import smoothscroll from 'smoothscroll-polyfill';

import 'core-js/modules/es.symbol';

import '../components/modal/javascripts';

import initImage from '../components/image/javascripts';
import initSocialEmbed from '../components/social-embed/javascripts';
import initRevealBox from '../components/reveal-box/javascripts';
import initInfobar from '../components/infobar/javascripts';
import initMaps from '../components/maps/javascripts';
import initEventList from '../components/event-list/javascripts';
import initImageGlow from '../components/image-glow/javascripts';
import initSlider from '../components/slider/javascripts';
import initMainStage from '../components/main-stage/javascripts';
import initNavigation from '../components/navigation/javascripts';
import initVideoLoop from '../components/video-loop/javascripts';
import initTeaserRow from '../components/teaser-row/javascripts';
import initSearch from '../components/search/javascripts';
import initContributorsList from '../components/contributors-list/javascripts';
import initContentText from '../components/content-text/javascripts';
import responsiveVideos from './helpers/responsiveVideos';

// import initBrowserVersionCheck from '../components/browser-version-check/javascript';

import initCalendar from '../components/calendar/javascripts';

// htmx initialization
// See https://htmx.org/docs/#webpack
window.htmx = htmx;

// kick off the polyfill!
smoothscroll.polyfill();

// calculate scrollbar width and assign to css variable so it can be used in the slider e.g.
const calcScrollbarWidth = () => {
  document.documentElement.style.setProperty('--scrollbar-width', `${document.body.offsetWidth - document.body.clientWidth}px`);
};
throttle(() => {
  calcScrollbarWidth();
}, 500);

function enhancedDomReady(init) {
  document.addEventListener('DOMContentLoaded', init);
  window.addEventListener('reload', init);
}

enhancedDomReady(() => {
  initImage();
  initImageGlow();
  initMainStage();
  initNavigation();
  initSearch();
  initSlider();
  initSocialEmbed();
  initContributorsList();
  initEventList();
  initMaps();
  initInfobar();
  initRevealBox();
  initVideoLoop();
  initTeaserRow();
  initCalendar();
  initContentText();
  calcScrollbarWidth();
  window.addEventListener('resize', throttle(responsiveVideos, 100), false);
  responsiveVideos();
});

// All links with target="_blank" attribute should have an attribute rel="noopener". This has performance and security reasons https://developers.google.com/web/tools/lighthouse/audits/noopener
const externalLinks = document.querySelectorAll("[target='_blank']");
if (externalLinks) {
  externalLinks.forEach((link) => {
    link.setAttribute('rel', 'noopener');
  });
}
