export default async function init() {
  const elements = document.querySelectorAll('.calendar');

  if (elements.length) {
    const { default: Calendar } = await import(/* webpackChunkName: "calendar" */ './Calendar.jsx');

    elements.forEach((element) => {
      new Calendar(element);
    });
  }
}
