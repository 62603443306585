import toggleClass from '../../../javascripts/dom/toggleClass';

class ContentText {
  constructor(element) {
    this.element = element;
    this.triggerShowMore = element.querySelector("[data-js-element = 'content-text__show-more']");
    this.triggerShowLess = element.querySelector("[data-js-element = 'content-text__show-less']");
    this.expanded = false;

    this.triggerShowMore.addEventListener('click', () => toggleClass(this.element, 'content-text--collapsed'));
    this.triggerShowLess.addEventListener('click', () => toggleClass(this.element, 'content-text--collapsed'));
  }

  toggle() {
    this.element.classList.toggle('content-text--collapsed');
  }
}

export default ContentText;
