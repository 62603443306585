import { LitElement, html, css } from 'lit';

class Modal extends LitElement {
  static get properties() {
    return {
      modalId: {
        type: String
      },
      closeLabel: {
        type: String
      },
      size: { type: String },
      height: { type: String },
      background: { type: String },
      initialFocusId: { type: String },
      color: { type: String },
      state: {
        type: String,
        hasChanged: (newVal, oldVal) => newVal !== oldVal
      }
    };
  }

  static get styles() {
    return [
      css`
        :host {
          display: none;
          --modal-max-width: '700px';
          --modal-background: var(--color-theme-background);
          --modal-color: var(--color-theme-color);
        }

        ::slotted(a) {
          text-decoration: none;
        }

        ::slotted(a):hover,
        ::slotted(a):focus {
          text-decoration: none;
        }

        .wrapper {
          align-items: center;
          justify-content: flex-end;
          position: fixed;
          top: 0;
          left: 0;
          width: 100vw;
          height: var(--modal-height);
          opacity: 0;
          transform: translateX(100%);
          visibility: hidden;
          display: flex;
          pointer-events: none;
          transition: opacity 0.4s cubic-bezier(0.78, 0, 0.52, 0.95), transform 0.4s cubic-bezier(0.78, 0, 0.52, 0.95);
          z-index: 999;
        }

        .shim-layer {
          background: rgba(0, 0, 0, 0);
          height: 100%;
          position: fixed;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          width: 100%;
        }

        .modal {
          background: var(--modal-background);
          color: var(--modal-color);
          height: var(--modal-height);
          overflow-y: scroll;
          -webkit-overflow-scrolling: touch;
          width: 100%;
          max-width: var(--modal-max-width);
          position: fixed;
          display: flex;
          flex-direction: column;
        }

        .modal-header {
          position: fixed;
          top: 0;
          padding: var(--space-xs);
          width: calc(var(--modal-max-width) - var(--space-s));
          display: flex;
          justify-content: flex-end;
          background: var(--modal-background);
          right: 0;
          color: var(--modal-color);
          z-index: 2;
        }

        .content {
          padding-top: var(--space-xl);
          flex-grow: 2;
          opacity: 0;
          transform: translateY(10px);
        }

        .wrapper[aria-hidden='false'] {
          opacity: 1;
          visibility: visible;
          pointer-events: auto;
          transform: translateX(0);
        }

        .wrapper[aria-hidden='false'] .content {
          transition: opacity 0.4s 0.4s cubic-bezier(0.78, 0, 0.52, 0.95), transform 0.4s 0.4s cubic-bezier(0.78, 0, 0.52, 0.95);
          opacity: 1;
          transform: translateY(0);
        }

        .close {
          cursor: pointer;
          display: flex;
          align-items: flex-end;
          justify-content: center;
          flex-direction: column;
          border: none;
          color: var(--modal-color);
          outline: none;
          appearance: none;
          background: none;
          font-family: 'Trade Gothic Condensed', Helvetica, Arial, sans-serif;
          font-size: var(--typo-topline-font-size);
          line-height: var(--typo-topline-line-height);
          letter-spacing: 3px;
          font-weight: bold;
          text-transform: uppercase;
          margin: 0;
        }

        .close span {
          display: block;
          height: 2px;
          width: 28px;
          margin-bottom: 6px;
          margin-top: 6px;
          background: var(--modal-color);
          transform: rotate(30deg);
        }

        .close span:nth-of-type(2) {
          margin-top: -8px;
          transform: rotate(-30deg);
        }

        .close div {
          margin-top: space(xs);
        }

        :host-context([data-whatinput='keyboard']) .close:focus {
          outline: 2px dashed currentColor;
          outline-offset: 2px;
          z-index: 1;
        }
      `
    ];
  }

  constructor() {
    super();
    this.closeLabel = 'Close';
    this.intialFocusElement = undefined;
  }

  connectedCallback() {
    super.connectedCallback();

    this.handleModalHeight();
    this.style.setProperty('display', 'block');
    this.style.setProperty('--modal-max-width', this.size === 'full' ? '100vw' : '700px');
    this.style.setProperty('--modal-background', this.background ? `var(--color-${this.background})` : 'var(--color-theme-background)');
    this.style.setProperty('--modal-color', this.color ? `var(--color-${this.color})` : 'var(--color-theme-color)');
  }

  firstUpdated() {
    this.focusElements = this.getFocusElements();

    window.addEventListener('resize', () => this.handleModalHeight());

    window.addEventListener(`modal-open-${this.modalId}`, () => this.handleOpen());
    window.addEventListener(`modal-update-${this.modalId}`, () => {
      this.focusElements = this.getFocusElements();
    });
  }

  getActiveElement(root = document) {
    if (root.activeElement && root.activeElement.shadowRoot && root.activeElement.shadowRoot.activeElement) {
      return this.getActiveElement(root.activeElement.shadowRoot);
    }

    return root.activeElement;
  }

  getFocusElements() {
    const selectors =
      'a[href], button:not([disabled]), textarea, input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select';
    const elements = [...this.shadowRoot.querySelectorAll(selectors), ...this.querySelectorAll(selectors)];

    if (this.initialFocusId) {
      const [focusElement] = elements.filter((item) => item.id === this.initialFocusId);
      if (focusElement) this.intialFocusElement = focusElement;
    }

    const first = elements ? elements[0] : null;
    const last = elements ? elements[elements.length - 1] : null;

    return [first, last];
  }

  handleKeyDown(e) {
    if (e.keyCode === 27) {
      this.handleClose();
      return;
    }

    if (e.keyCode !== 9) return;

    const [first, last] = this.focusElements;
    const activeElement = this.getActiveElement();

    if (!e.shiftKey && activeElement === last) {
      e.preventDefault();
      first.focus();
    } else if (e.shiftKey && activeElement === first) {
      e.preventDefault();
      last.focus();
    }
  }

  handleOpen() {
    if (this.state === 'open') return;
    this.state = 'open';

    setTimeout(() => {
      document.body.style.overflow = 'hidden';
      if (this.intialFocusElement && this.intialFocusElement.focus) {
        this.intialFocusElement.focus();
      } else {
        this.shadowRoot.querySelector('button').focus();
      }

      document.addEventListener('keydown', (e) => this.handleKeyDown(e), true);
    });
  }

  handleModalHeight() {
    this.style.setProperty('--modal-height', `${window.innerHeight}px`);
  }

  handleClose() {
    if (this.state === 'close') return;
    this.state = 'close';
    document.body.style.overflow = 'visible';
    document.removeEventListener('keydown', (e) => this.handleKeyDown(e), true);

    const ev = new CustomEvent(`modal-close-${this.modalId}`, {
      bubbles: true
    });
    this.dispatchEvent(ev);
  }

  render() {
    return html`
      <div tabindex="${this.state === 'open' ? '0' : '-1'}" class="wrapper" aria-hidden="${this.state === 'close'}">
        <div class="shim-layer" @click="${this.handleClose}"></div>

        <div class="modal" role="dialog" aria-describedby="content-${this.modalId}">
          <div class="modal-header">
            <button class="close" aria-label="${this.closeLabel}" @click=${this.handleClose}>
              <span></span>
              <span></span>

              <div>${this.closeLabel}</div>
            </button>
          </div>

          <div id="content-${this.modalId}" class="content">
            <slot></slot>
          </div>
        </div>
      </div>
    `;
  }
}

customElements.define('kpn-modal-body', Modal);
